import React, { ReactNode } from 'react'
import Slider from 'react-slick'

export const SliderContainer = ({
  children,
  count,
  defaultCount,
}: {
  children: ReactNode
  count: number
  defaultCount: number
}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: count < defaultCount ? count : defaultCount,
    slidesToScroll: count < defaultCount ? count : defaultCount,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: count < defaultCount ? count : defaultCount,
          slidesToScroll: count < defaultCount ? count : defaultCount,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: count < 2 ? count : 2,
          slidesToScroll: count < 2 ? count : 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: count < 2 ? count : 2,
          slidesToScroll: count < 2 ? count : 2,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: count < 2 ? count : 2,
          slidesToScroll: count < 2 ? count : 2,
        },
      },
    ],
  }
  return <Slider {...settings} className=''>{children}</Slider>
}
