import create from 'zustand'
type Anchor = 'left' | 'right';


interface Iprops {
    open: Anchor | null,
    toggleDrawer: (status?: boolean) => any

}
export const useStore = create<Iprops>()((set) => ({

    open: null,
    toggleDrawer: (status) => set((state) => ({
        open: (state.open === "left" || state.open === "right") || status === false ? null : "left"
    }))
}))