
import Cookies from 'js-cookie'

type link = {
    icon: any,
    path: string,
    text: string
}
type NavbarLinks = link[]
export const NAVBAR_LINKS: NavbarLinks = [
    {
        icon: "help",
        path: "/help",
        text: "navbar.help"
    },
    {
        icon: "privacy",
        path: "/privacyterms",
        text: "navbar.privacy"
    },
    {
        icon: "opinion",
        path: "/share_opinion",
        text: "navbar.opinion"
    },
    {
        icon: "messages",
        path: "/message",
        text: "navbar.message"
    },

    {
        icon: "user",
        path: Cookies.get("deal-user") ? "/profile" : "/login",
        text: Cookies.get("deal-user") ? "navbar.profile" : "navbar.login"
    },
    {
        icon: "bell",
        path: "#",
        text: "navbar.bell"
    },
    {
        icon: "home",
        path: "/home",
        text: "navbar.home"
    },
] 