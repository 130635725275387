import axios, {  AxiosInstance } from 'axios'
import { apiError } from 'lib'
import { toast } from 'react-toastify'
export const request: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: { 'Show-Notification': true },
}) as any

request.interceptors.response.use(
  (res) => {
    if (
      res.status === 200 &&
      res.config.headers &&
      res.config.headers['Show-Notification']
    )
      toast.success(res.data.message)
    return res.data
  },
  (error) => {
    apiError(error)
    if (error.response.status === 404) {
      throw new Error(`${error.config.url} not found`)
    }
    throw error
  },
)

export const { get, post } = request;
