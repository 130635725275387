import React from 'react'
import ReactDOM from 'react-dom/client'
import 'styles/index.css'
import App from './App'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { HelmetProvider } from 'react-helmet-async'
import 'i18n'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 24000000000,
    },
  },
})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <QueryClientProvider client={client}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </QueryClientProvider>
  </>,
)
