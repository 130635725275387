import React, { FC, ReactNode } from 'react'

interface props {
    children: ReactNode
}
export const Card: FC<props> = ({ children }) => {
    return (
        <div className='card'>
            {children}
        </div>
    )
}
