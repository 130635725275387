import Cookies from "js-cookie"
import { lang } from './lang'
/* export const config = {
    locale: 'en',
    Authorization: `Bearer ${sessionStorage.getItem("deal-admin")}`
} */

export const config = {
    "Language": lang === "en-US" ? "en" : lang,
    Authorization: `Bearer ${Cookies.get("deal-user")}`
} as any