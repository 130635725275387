import create from 'zustand'




interface Iprops {
    notifications: [],
    initialnotifications: (data: []) => void

}
export const useStore = create<Iprops>()((set) => ({

    notifications: [],
    initialnotifications: (data) => set({
        notifications: data
    })
}))