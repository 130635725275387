import { AxiosError, AxiosRequestConfig } from "axios"
import { toast } from "react-toastify"

import Cookies from 'js-cookie'
type errorsTypes = { [key: string]: string[] }
type props = {
    message?: string
    errors?: errorsTypes
}


const ShowNotification = (config: AxiosRequestConfig<any>): boolean => config.headers && config?.headers["Show-Notification"] ? true : false

type ToastProps = {
    [key: number]: any
}
const toasMessage: ToastProps = {
    0: () => toast.error("Unknown Error happend that we didn't expect"),
    500: () => toast.error("Unknown Error happend that we didn't expect"),


}
export const apiError = (error: AxiosError | any) => {
    /* eslint-disable */

    if (error.response?.status === 400) toast.error(error.response.data.message)
    else if (error.response?.status === 404) toast.error(error.response.data.message)
    else if (error.response?.status === 401) {
        toast.error(error.response.data.message)
        Cookies.remove("deal-user")
        setTimeout(() => {
            window.location.href = "/login"
        }, 2000)


    }
    else if (error.response?.status === 422) {

        const errors = error.response.data.errors
        try {
            Object.keys(errors).map((item) => toast.error(errors[item][0]))
        } catch {
            toast.error(error.response.data?.message)
        }
    }
    else if (error.response?.status === 413) {

        toast.error(error.response.data?.message)
     
    }
    else {
        toasMessage[error?.response?.status ? error?.response?.status : 0]
    }


}