import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
export const Meta = ({ children, title, description }: { children: ReactNode, title: string, description: string }) => {
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            {children}
        </div>
    )
}
