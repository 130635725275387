import { Navigate, Outlet, useLocation } from 'react-router-dom'
import type { ReactElement } from 'react'
import { MainLayout } from 'layout'
import Cookies from 'js-cookie'

export const UserGuard = (props: any): ReactElement => {
    const { pathname } = useLocation()

    return !Cookies.get("deal-user") && [ '/profile', '/profile/ads', '/profile/edit',  '/share_opinion', '/create', '/create/job', '/create/car','/add','/message','/pay'].includes(pathname) ? <Navigate to={'/login'} /> : <MainLayout> <Outlet /></MainLayout>

} 

