export const company=[
    {
        text:"information_us",
        path:""
    },
    {
        text:"advertising",
        path:""
    },
    {
        text:"careers",
        path:""
    },
    {
        text:"terms",
        path:""
    },
    {
        text:"policy",
        path:""
    },
    {
        text:"privacy",
        path:""
    },
    {
        text:"articles",
        path:""
    },
]