import React, { ReactNode, useEffect, useState } from 'react'
/* Router */
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
/* styles */
import classes from 'styles/modules/Ui/navbar.module.css'
/* stores */
import { useStore } from 'stores/DrawerStatUs'
/* constants */
import { NAVBAR_LINKS } from 'constants/NAVBAR_LINKS'
/* images */
import logo from 'assets/images/deal-logo.svg'
/* LANGUAGE LIBRARY*/
import { useTranslation } from 'react-i18next'
/* my component */
import { GradienIcon } from 'components/shared'
/* MUI component */
import { Drawer } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
/*  */
import clsx from 'clsx'

import { Notifications } from 'feature/notifications/notifications'

/* ICONS */
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import MapsUgcRoundedIcon from '@mui/icons-material/MapsUgcRounded';

const styleIcon = {
  fill: "url(#linearColors) !important"
} as any
const icons = {
  "help": <HelpOutlineOutlinedIcon sx={styleIcon} />,
  "home": <HomeOutlinedIcon sx={styleIcon} />,
  "privacy": <LockOutlinedIcon sx={styleIcon} />,
  "bell": <NotificationsActiveOutlinedIcon sx={styleIcon} />,
  "user": <PersonOutlineOutlinedIcon sx={styleIcon} />,
  "messages": <QuestionAnswerOutlinedIcon sx={styleIcon} />,
  "opinion": <MapsUgcRoundedIcon sx={styleIcon} />
} as { [key: string]: ReactNode }
export const Navbar = () => {
  const [screenWidth, setScreenWidth] = useState<any>(0)
  const [isNotifications, setisNotifications] = useState<boolean>(false)
  const { t } = useTranslation("user")
  const state = useStore(state => state.open)
  const toggleDrawer = useStore(state => state.toggleDrawer)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {

    const listner = () => {
      setScreenWidth(window.innerWidth)
    }
    setScreenWidth(window.innerWidth)
    window.addEventListener('resize', listner)

    return () => window.removeEventListener('resize', listner)
  }, [screenWidth])

  return (
    <nav style={{ direction: "ltr" }}>
      <div className={clsx("container", classes.container)}>
        <img src={logo} loading='lazy' alt="Deal" width='100' height='auto' style={{ marginInlineEnd: "1rem", cursor: "pointer" }} onClick={() => navigate("/home")} />
        <button className='button-primary' onClick={() => navigate('/create')}>{t("navbar.ad")}</button>


        {screenWidth <= 991 && <>

          {screenWidth >= 350 &&
            <div style={{ width: 'fit-content', position: 'relative', marginInlineStart: 'auto' }} >
              <NotificationsActiveOutlinedIcon sx={{ fill: "url(#linearColors) !important", marginInlineStart: "auto", marginInlineEnd: '.5rem', cursor: "pointer", padding: "2px", w: 20, h: 20 }} onClick={() => setisNotifications(prev => !prev)} />
              {isNotifications && <Notifications setisNotifications={setisNotifications} />}
            </div>
          }
          <GradienIcon>


            <MenuIcon className={"openMenu"} sx={{ fill: "url(#linearColors) !important", margin: "0", cursor: "pointer", padding: "2px", w: 20, h: 20 }} onClick={() => toggleDrawer(true)} />
          </GradienIcon>

          <div className={classes.mobile_list}>
            <Drawer
              anchor={"left"}
              open={state === "left"}
              onClose={() => toggleDrawer(false)}
              PaperProps={{
                sx: {
                  width: 250,
                },
              }}
            >
              <ul className={classes.menuMobile} style={{ padding: "50px 20px", listStyle: "none" }}>

                {
                  NAVBAR_LINKS.slice(0).reverse().map((item, index) => {
                    if (item.path === '#' && screenWidth > 350) return
                    return <li key={index} style={{ width: "fit-content", marginBlock: "30px", position: 'relative' }}><Link to={item.path} className={`${pathname.includes(item.path) && classes.active}`} onClick={() => {
                      toggleDrawer(false)
                      item.path === '#' && setisNotifications(prev => !prev)
                    }} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <GradienIcon >
                        {icons[item.icon]}
                      </GradienIcon>
                      <span> {t(item.text)}</span>

                      {(isNotifications && item.path === '#' && screenWidth < 350) && <Navigate to='/notifications' />}
                    </Link></li>
                  })
                }
              </ul>
            </Drawer>
          </div></>}

        {screenWidth > 991 && <ul className={classes.list}>
          {
            NAVBAR_LINKS.map((item, index) => <li key={index} style={{ position: 'relative' }} ><Link to={item.path} className={`${pathname.includes(item.path) && classes.active}`} onClick={() => {
              item.path === '#' && setisNotifications(prev => !prev)
            }}>
              <GradienIcon>
                {icons[item.icon]}
              </GradienIcon>
              <span> {t(item.text)}</span>

            </Link>   {isNotifications && item.path === '#' && <Notifications setisNotifications={setisNotifications} />}</li>)
          }
        </ul>}
      </div>
    </nav>
  )
}
