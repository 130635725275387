import React, { useState } from 'react'
import imageError from 'assets/images/imageError.png'

interface props extends React.ImgHTMLAttributes<any> {
    onErrror?: (e?: React.ReactEventHandler<HTMLImageElement>) => any
    style?: React.CSSProperties

}
export const Image: React.FC<props> = ({ onErrror, style, ...restprops }) => {
    const [LoadImage, setLoadImage] = useState<boolean>(false)
    return (
        <>

            {!LoadImage && <div className='absolute'> <div className="spinner-image"></div></div>}
            <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; 
                currentTarget.src = imageError;
                onErrror && onErrror()
            }} onLoad={() => setLoadImage(prev => true)} style={{
                ...(!LoadImage && {
                    width: 0,
                    height: 0
                }),
                ...style
            }} {...restprops} />

        </>
    )
}
