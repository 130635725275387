import React from 'react'
import { getIn, FormikErrors, FormikProps } from 'formik';

function getStyles(errors: FormikErrors<any>, fieldName: string | undefined) {

    if (fieldName && getIn(errors, fieldName)) {
        return {
            border: '1px solid red'
        }
    }
}

export const TextAreaValidation = ({ field, form: { formik } }: {
    field: React.TextareaHTMLAttributes<HTMLTextAreaElement>, form: {
        formik:any
    }
}) =>
    <textarea {...field} style={formik.touched[`${field.name}`] && getStyles(formik.errors, field.name)} />