import Cookies from 'js-cookie'
import create from 'zustand'

interface Iprops {
  Country: any
  city: any
  initialCity: (id: string) => void
  initialCountry: (data: string) => void
  cities: any[]
  initialcities: (data: any[]) => void
  lang: string
  initialLang: (lang: string) => void
}
export const useStore = create<Iprops>()((set) => ({
  Country: null || Cookies.get('deal-country'),
  city: '',
  lang: localStorage.getItem('i18nextLng') || '',
  cities: [],

  initialLang: (lang) =>
    set({
      lang: lang,
    }),
  initialCountry: (data) =>
    set({
      Country: data,
    }),
  initialCity: (id) =>
    set({
      city: id,
    }),
  initialcities: (data) =>
    set({
      cities: data,
    }),
}))
