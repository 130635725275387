import React from 'react'
import { getIn, FormikErrors } from 'formik';

function getStyles(errors: FormikErrors<any>, fieldName: string | undefined) {

    if (fieldName && getIn(errors, fieldName)) {
        return {
            border: '1px solid red'
        }
    }
}

export const InputValidation = ({ field, form: { formik } }: {
    field: React.InputHTMLAttributes<HTMLInputElement>, form: {
        formik: any
    }
}) =>
    <input {...field} style={formik.touched[`${field.name}`] && getStyles(formik.errors, field.name)} />