import React, { ReactNode, useState, useEffect } from 'react'
import classes from 'styles/modules/SelectBox.module.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import clsx from 'clsx';
interface Props {
  placeholder?: string
  options?: any[]
  label: string,
  label2?: string,
  value: string,
  menuHeight?: `${number} px`
  onSelect?: any
  resetSelectBox?: any
  message?: string
  style?: React.CSSProperties
  Menustyle?: React.CSSProperties
  inputStyle?: React.CSSProperties
  wrapper__selectBox?: React.CSSProperties
  inputHeight?: `${number}px`
  image?: string
  symbol?: string
  symbol2?: string
  className?: string
  selectedLabel?: string
  setSelectedValue?: boolean
}
export const SelectBox: React.FC<Props> = ({ options, placeholder, label, label2, value = '', style, inputStyle, menuHeight, className = "", symbol, symbol2, wrapper__selectBox, resetSelectBox = false, onSelect, message, inputHeight = '33px', image, selectedLabel, setSelectedValue = false }) => {
  const [search, setSearch] = useState<string>("")
  const [selected, setSelected] = useState<boolean>()
  const [active, setActive] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  useEffect(() => {
    if (options)
      setData(options)
  }, [options])
  useEffect(() => {
    if (!!search && resetSelectBox) {
      setSearch("")

    }

  }, [resetSelectBox])

  useEffect(() => {
    setSelected(false)
    if (options && !selected) {
      const filter = options?.filter((item) =>
        String(item[label]).toLocaleLowerCase()?.includes(search.toLocaleLowerCase()),
      )
      setData(filter)
    } else setData(options ?? [])
  }, [search])
  return (

    <div className={clsx(classes.SelectWrap, className)} style={style}>
      {active && <div style={{
        position: 'fixed',
        inset: 0,

        zIndex: 100000
      }} onClick={() => setActive(false)}></div>}
      <div className={clsx(classes.default_option, 'wrapper__selectBox')} style={{
        ...wrapper__selectBox,
        height: inputHeight
      }}>
        <input type="text" value={resetSelectBox ? setSelectedValue ? selectedLabel : '' : search ? search : selectedLabel} onChange={(e) => setSearch(e.target.value)} placeholder={placeholder ?? "search..."} onFocus={() => setActive(true)} className={"select__input"} style={inputStyle} />
        <ArrowDropDownIcon onClick={() => setActive((prev) => !prev)} />
      </div>
      <div className={clsx(classes.select_ul, active && classes.active)} style={
        {
          ...(menuHeight && { height: menuHeight }),
          ...((options && options?.length === 0 && active === true) && { height: '50px', display: 'grid', placeItems: 'center' }),


        }
      }>
        {
          data.length > 0 ?
            data?.map((item: any, index: number) => {
              return <div key={index} onClick={() => {
                setSearch(!label2 ? eval(`item?.${label}`) : eval(`item?.${label} + symbol +item?.${label2}+ symbol2 `))
                setSelected(true)
                setActive(false)
                onSelect && onSelect(eval(`item?.${value}`))
              }} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '1000' }}>
                <p style={{ pointerEvents: 'none' }}>{eval(`item?.${label}`)} {symbol}{label2 && eval(`item?.${label2}`)} {symbol2}</p>
                {image && <img src={process.env.REACT_APP_IMAGE_URL + eval(`item?.${image}`)} width="25" height="25" />}
              </div>
            }) :
            <p style={{ pointerEvents: 'none', textAlign: 'center', display: 'grid', placeItems: 'center', fontSize: '11px' }}>{message}</p>

        }
      </div>
    </div>

  )
}

