import create from 'zustand'


interface Iprops {
    social: {
        [key: string]: string
    }
    initialSocial: (data: {}) => void
}
export const useStore = create<Iprops>()((set) => ({

    social: {},
    initialSocial: (data) => set({
        social: data
    })
}))