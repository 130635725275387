import React, { useEffect, useState } from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { Routes } from 'routes'
import ReactGA from 'react-ga'

ReactGA.initialize("UA-242715262-1")

const App = () => {

  useEffect(() => {
    window.onbeforeunload = function () {
      sessionStorage.clear()
    }
  }, [])
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default App