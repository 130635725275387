import React, { ReactNode } from 'react'

export const GradienIcon = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <svg width={0} height={0}>
                <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="#5CC6E0" />
                    <stop offset={1} stopColor="#E3A263" />
                </linearGradient>
            </svg>
            {children}
        </>
    )
}
