type propsLanguage = {
    [key: string]: {
        text: string,
        icon: string,
        symbol:string
    }
}
export const languages: propsLanguage = {
    en: {
        text: "English",
        icon: "/images/flag_en.svg",
        symbol:"en"

    },
    fr: {
        text: "French",
        icon: "/images/flag_fr.svg",
        symbol:"fr"
    },
    ar: {
        text: "Arabic",
        icon: "/images/flag_ar.svg",
        symbol:"ar"

    },
    ku: {
        text: "kurdistan",
        icon: "/images/flag_ku.svg",
        symbol:"kur"

    },
} 