import  { FC, ReactNode, useEffect, useState } from 'react'
/* Router */
import { Navigate, useLocation } from 'react-router-dom'
/* styles */
import 'styles/user.css'
/* my components */
import { Navbar, Footer } from 'components/ui'

/* apis */
import { general } from 'apis/general'
/* stores */
import { useStore } from 'stores/general'

/*  */
import i18n from 'i18n';
import clsx from 'clsx';
import ReactGA from 'react-ga'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Iprops {
    navbar?: true | false
    footer?: true | false
    children: ReactNode
}

export const MainLayout: FC<Iprops> = ({ children }) => {
    const initialcities = useStore(state => state.initialcities)
    const initialCity = useStore(state => state.initialCity)
    const cities = useStore(state => state.cities)
    const Country = useStore(state => state.Country)
    const lang = useStore(state => state.lang)
    const initialLang = useStore(state => state.initialLang)

    const { pathname } = useLocation()
    const UI = () => !['/'].includes(pathname)
    const [prevLoc, setPrevLoc] = useState("")
    const location = useLocation()
    useEffect(() => {
        setPrevLoc(location.pathname)
        if (location.pathname === prevLoc) {
            setPrevLoc('')
        }
    }, [location])
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [prevLoc])
    useEffect(() => { initialLang(localStorage.getItem("i18nextLng") || "en"); document.body.className = i18n.language }, [lang, i18n.language])
    useEffect(() => {

        const languages = ['ar', 'en', 'fr', 'kur']
        if (languages.includes(i18n.language)) {
            localStorage.setItem("i18nextLng", i18n.language)
            localStorage.setItem("deal-language", i18n.language)
        } else {
            localStorage.setItem("i18nextLng", "en")
            localStorage.setItem("deal-language", "en")
            window.location.reload();
        }

    }
        , [i18n.language])
    useEffect(() => {
        if (cities?.length === 0 && Country)
            general.cities({ countryID: JSON.parse(Country)?.id }).then(res => {
                initialcities(res.data)
                initialCity(res.data[0])
            });
    }, [Country])
    if (!Country && pathname != "/") return <Navigate to="/" />
    return (
        <>
            <div className={clsx('user-Wrapper', (i18n.language === "ar" || lang === "ar") && "rtl")}>

                {UI() && <Navbar />}
                <main className='container'>
                    {children}


                </main>

                {UI() && <Footer />}
                <ToastContainer
                    position={lang === "ar" ? "bottom-right" : "bottom-left"}
                    style={{
                        fontSize: "14px"
                    }}
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}

                />
            </div>
        </>
    )
}
