import { useRoutes } from 'react-router-dom'
import { UserGuard } from 'guards'
import loadable, { LoadableComponent } from '@loadable/component'

interface pageProps {
  [key: string]: LoadableComponent<any>
}

const Loading = () => <div style={{ display: 'grid', width: '100%', height: '50vh', placeItems: 'center' }}> <div className="spinner-image"></div></div>

const UserPages = {
  Index: loadable(() => import('pages/index'), { fallback: <div style={{ display: 'grid', width: '100%', height: '100vh', placeItems: 'center' }}> <div className="spinner-image"></div></div> }),
  Home: loadable(() => import('pages/home'), { fallback: <Loading /> }),
  Registrashion: loadable(() => import('pages/register&login'), { fallback: <Loading /> }),
  Profile: loadable(() => import('pages/profile/profile'), { fallback: <Loading /> }),
  Ads: loadable(() => import('pages/profile/Ads')),
  Offers: loadable(() => import('pages/profile/Offers')),
  Messages: loadable(() => import('pages/messages')),
  EditProfile: loadable(() => import('pages/profile/Edit')),
  VerifyEmail: loadable(() => import('pages/verifyEmail'), { fallback: <Loading /> }),
  ResetPassword: loadable(() => import('pages/resetpassword'), { fallback: <Loading /> }),
  Privacyterms: loadable(() => import('pages/privacyterms'), { fallback: <Loading /> }),
  ShareOpinion: loadable(() => import('pages/share_opinion'), { fallback: <Loading /> }),
  Help: loadable(() => import('pages/help'), { fallback: <Loading /> }),
  Pay: loadable(() => import('pages/pay'), { fallback: <Loading /> }),
  Notifications: loadable(() => import('pages/notifications'), { fallback: <Loading /> }),
  Create: loadable(() => import('pages/create announcement/index'), { fallback: <Loading /> }),
  CreatePost: loadable(() => import('pages/create announcement/post/create_post'), { fallback: <Loading /> }),
  CreateVehicle: loadable(() => import('pages/create announcement/vehicle/Create_vehicle'), { fallback: <Loading /> }),
  CreateJob: loadable(() => import('pages//create announcement/job/Create_job'), { fallback: <Loading /> }),
  CreateEstate: loadable(() => import('pages/create announcement/estate/Create_Estate'), { fallback: <Loading /> }),
  EditPost: loadable(() => import('pages//edit announcement/post/edit_post'), { fallback: <Loading /> }),
  EditVehicle: loadable(() => import('pages/edit announcement/vehicle/edit_vehicle'), { fallback: <Loading /> }),
  EditJob: loadable(() => import('pages/edit announcement/job/edit_job'), { fallback: <Loading /> }),
  EditEstate: loadable(() => import('pages/edit announcement/estate/edit_Estate'), { fallback: <Loading /> }),

  Jobs: loadable(() => import('pages/Ads/jobs'), { fallback: <Loading /> }),
  JobsDetail: loadable(() => import('pages/Ads/jobs/detail'), { fallback: <Loading /> }),
  Posts: loadable(() => import('pages/Ads/post'), { fallback: <Loading /> }),
  PostDetail: loadable(() => import('pages/Ads/post/detail'), { fallback: <Loading /> }),
  Estate: loadable(() => import('pages/Ads/Estate'), { fallback: <Loading /> }),
  EstateDetail: loadable(() => import('pages/Ads/Estate/detail'), { fallback: <Loading /> }),
  Vehicle: loadable(() => import('pages/Ads/vehicle'), { fallback: <Loading /> }),
  VehicleDetail: loadable(() => import('pages/Ads/vehicle/detail'), { fallback: <Loading /> }),
  Search: loadable(() => import('pages/search'), { fallback: <Loading /> }),
  SearchDetail: loadable(() => import('pages/search/detail'), { fallback: <Loading /> }),
  Error404: loadable(() => import('pages/404'), { fallback: <Loading /> }),
} as pageProps


export const Routes = () => {
  return useRoutes([

    {
      path: '/',
      element: <UserGuard />,
      children: [
        {
          index: true,
          element: <UserPages.Index />,
        },
        {
          path: 'home',
          element: <UserPages.Home />,
        },
        {
          path: 'login',
          element: <UserPages.Registrashion />,
        },
        {
          path: 'verifyemail',
          element: <UserPages.VerifyEmail />,
        },
        {
          path: 'resetpassword',
          element: <UserPages.ResetPassword />,
        },

        {
          path: 'notifications',
          element: <UserPages.Notifications />,
        },
        {
          path: 'pay',
          element: <UserPages.Pay />,
        },
        {
          path: 'profile',
          element: <UserPages.Profile />,
          children: [
            {
              index: true,
              element: <UserPages.Ads />,
            },
            {
              path: 'offers',
              element: <UserPages.Offers />,
            },
            {
              path: 'edit',
              element: <UserPages.EditProfile />,
            },
          ],
        },

        {
          path: 'message',
          element: <UserPages.Messages />,
        },
        {
          path: 'privacyterms',
          element: <UserPages.Privacyterms />,
        },
        {
          path: 'share_opinion',
          element: <UserPages.ShareOpinion />,
        },
        {
          path: 'help',
          element: <UserPages.Help />,
        },
        {
          path: 'create',
          element: <UserPages.Create />,
        },
        {
          path: 'create/vehicle',
          element: <UserPages.CreateVehicle />,
        },
        {
          path: 'create/job',
          element: <UserPages.CreateJob />,
        },
        {
          path: 'create/estate',
          element: <UserPages.CreateEstate />,
        },
        {
          path: 'create/:slug',
          element: <UserPages.CreatePost />,
        },
        {
          path: 'edit/vehicle',
          element: <UserPages.EditVehicle />,
        },
        {
          path: 'edit/job',
          element: <UserPages.EditJob />,
        },
        {
          path: 'edit/estate',
          element: <UserPages.EditEstate />,
        },
        {
          path: 'edit/post',
          element: <UserPages.EditPost />,
        },
        {
          path: 'post/:slug',
          element: <UserPages.Posts />,
        },
        {
          path: 'post/:slug/:id',
          element: <UserPages.PostDetail />,
        },
        {
          path: 'estate',
          element: <UserPages.Estate />,
        },
        {
          path: 'estate/:id',
          element: <UserPages.EstateDetail />,
        },
        {
          path: 'vehicle',
          element: <UserPages.Vehicle />,
        },
        {
          path: 'vehicle/:id',
          element: <UserPages.VehicleDetail />,
        },
        {
          path: 'job',
          element: <UserPages.Jobs />,
        },
        {
          path: 'job/:id',
          element: <UserPages.JobsDetail />,
        },
        {
          path: 'ads/search',
          element: <UserPages.Search />,
        },
        {
          path: 'ads/search/:id',
          element: <UserPages.SearchDetail />,
        },
        {
          path: ':id',
          element: <UserPages.Error404 />,
        },
      ],
    },

  ])
}
