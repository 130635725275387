import React, { useEffect, useState } from 'react'
/* react router */
import { useNavigate } from 'react-router-dom'
/* apis */
import { general } from 'apis/general'
/* stores */
import { useStore } from 'stores/notifications'

/*  */
import { Skeleton } from '@mui/material'
export const Notifications = ({ setisNotifications }: any) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const { initialnotifications, notifications } = useStore(state => state)
    useEffect(() => {
        if (notifications.length !== 0) return
        setLoading(true)
        general.notification().then((data: any) => initialnotifications(data)).finally(() => setLoading(false))
    }, [])
    return (
        <div >
            <div onClick={() => setisNotifications(false)} style={{ position: 'fixed', inset: 0, zIndex: 1 }} ></div>
            <div className='notification-wrapper'>
                <div className='scroll'>
                    {
                        notifications?.map((item: any) => <div key={item?.id} className='notification ' style={{ zIndex: 100, marginBlock: '.5rem' }} onClick={() => {
                            setisNotifications(false)
                            navigate('/profile')
                        }}>
                            <h4>{item.title}</h4>
                            <span>{item.body}</span>
                        </div>)
                    }
                    {
                        loading &&
                        <div style={{ zIndex: 100 }}>
                            {loading &&
                                Array(10)
                                    .fill(1)
                                    .map((_, index) =>
                                        <Skeleton key={index} variant="rectangular" height='50px' sx={{ borderRadius: '10px', marginBlock: '.5rem' }} />
                                    )
                            }
                        </div>
                    }
                </div>
            </div></div>
    )
}
